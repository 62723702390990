* {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
}

.main-content {
  margin-left: 120px;
  padding-top: 70px;
  margin-right: 20px;
}

nav {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 40px;
  background-color: rgba(255, 255, 255);
  display: flex;
  align-items: center;
  gap: 10%;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.menu-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 18px;
}

.logo-btns {
  display: flex;
  align-items: center;
  width: 100%;
}

.logo {
  margin-right: 8px;
  width: 32px;
  height: auto;
}

.btns {
  display: flex;
  justify-content: flex-end;
  display: flex;
  width: 90%;
  gap: 20px;
}

.Login {
  width: auto;
  height: fit-content;
  padding: 10px 20px 10px 20px;
  border: 1px solid rgba(35, 4, 146, 1);
  border-radius: 8px;
}

.Signup {
  background-color: rgba(35, 4, 146, 1);
  width: auto;
  height: fit-content;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  color: white;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px;
}

.contentDiv {
  margin-bottom: 20px;
}

.contentText {
  font-size: 24px;
  text-align: center;
  margin: 0;
}

.ui.login.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

form input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}

.links a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.links a:hover {
  text-decoration: underline;
}

.boxes {
  display: flex;
  justify-content: center;
}

.top {
  height: max-content;
}
.bottom {
  height: max-content;
}

/* Base Styles */
.about {
  padding: 20px;
}

.boxes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.box {
  flex: 1;
  margin: 10px;
  text-align: center;
}

.abtimg {
  max-width: 50%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.txt {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .boxes {
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 100%;
    margin: 15px 0;
  }

  .txt {
    font-size: 0.9rem;
    font-weight: bold;
  }
}

/* Larger Screen Styles */
@media (min-width: 769px) {
  .boxes {
    flex-direction: row;
  }

  .box {
    flex: 0 0 30%;
  }

  .txt {
    font-size: 1rem;
  }
}

.account-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.account-header {
  font-size: 24px;
  margin-bottom: 10px;
}
.account-sub-header {
  color: #666;
  margin-bottom: 20px;
}
.alert-box {
  background-color: #e6f7ff;
  padding: 20px;
  border-left: 4px solid #0073b1;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alert-box p {
  margin: 0;
}
.alert-box button {
  background-color: #0073b1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.alert-box button:hover {
  background-color: #005580;
}
.info-box {
  background-color: #fff7e6;
  padding: 20px;
  border-left: 4px solid #faad14;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.info-box p {
  margin: 0;
}
.profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.profile-info {
  line-height: 1.2;
}
.profile-info .name {
  font-size: 18px;
  font-weight: bold;
}
.profile-info .title {
  color: #666;
  font-size: 14px;
}

button {
  background-color: #0073b1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.ui.top.fixed.menu {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.ui.left.fixed.menu {
  margin-top: 4.6rem;
  margin-right: 0px;
}

.ui.top.fixed.menu.secondary {
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  margin-top: 65px;
  margin-left: 125px;
  margin-right: auto;
  width: 66.5%;
}

.main-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.left-section {
  flex: 1;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  max-width: calc(100vw - 500px);
}

.right-section {
  flex: 1;
  background-color: #fff;
  scrollbar-width: 1px;
  padding: 20px;
  position: fixed;
  top: 60px;
  bottom: 0;
  right: 0;
  width: 30vw;
  overflow-y: auto;
}

.post-container {
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 16px;
  margin-bottom: 10px;
}

.post-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.avatar {
  display: flex;
  align-items: center;
}

.post-slideshow {
  position: relative;
  margin-bottom: 10px;
  margin-left: 50px;
}

.post-slideshow img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 8px;
}

#slide-image {
  max-width: 400px;
  max-height: 400px;
  border-radius: 8px;
}

.slide-counter {
  position: absolute;
  top: -238px;
  right: 201px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.post-slideshow .slide-nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.post-slideshow .slide-nav button {
  background: #fff;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  color: #666;
  font-size: 18px;
  margin-left: 200px;
  margin-right: 220px;
}

.post-slideshow .slide-nav button:hover {
  color: #0077b5;
}

.post-video {
  position: relative;
  align-content: center;
  margin-left: 50px;
  border-radius: 8px;
}

.post-video video {
  max-width: 500px;
  max-height: 500px;
  border-radius: 8px;
}

#shared-video {
  max-width: 400px;
  max-height: 400px;
  border-radius: 8px;
}

.post-content {
  margin-left: 50px;
  margin-bottom: 10px;
  margin-top: 5px;
  position: relative;
  white-space: pre-line;
}

.content-preview {
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
}

.content-full {
  display: none;
}

.see-more-link {
  color: blue;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white; /* Add a background color to cover the last words */
  padding: 0 5px; /* Adjust padding for spacing */
}

.post-footer {
  margin-top: 10px;
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.post-footer .time {
  color: #666;
  font-size: 12px;
  margin-left: auto;
}

.go-back-btn {
  position: fixed;
  width: "44%";
  top: 60px;
  background-color: "white";
  padding-top: 10px;
  padding-bottom: 5px;
  padding-right: 10px;
}

#club-nav-menu {
  position: fixed;
  top: 60px;
  left: 100px;
  width: 280px;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  height: calc(100vh - 60px);
  z-index: 99;
}

#channel-nav-menu {
  margin-left: 380px;
}

.club-detailed-page {
  margin-left: 280px;
  margin-right: 20px;
  margin-top: 50px;
}

#manage-club-menu {
  position: fixed;
  top: 60px;
  left: 100px;
  background-color: #f2f2f2;
}

.card-container {
  display: flex;
  flex-wrap: wrap; /* Allowing the cards to wrap to the next line if necessary */
  gap: 32px; /* Spacing between cards */
}

.card-item {
  display: flex;
  height: 260px;
  width: 200px;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  align-items: center; /* Horizontally center content */
  position: relative;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-item img {
  width: 160px;
  margin-top: 24px;
  height: auto;
  display: block;
  border-radius: 8px;
}

.card-content {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 180px;
}

.card-options {
  position: absolute;
  top: 10px;
  right: 16px;
}

.card-label {
  position: absolute;
  top: 10px;
  left: 16px; /* Adjust the distance from the left */
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 16%; /* Make it circular */
  background-color: #007bff; /* Background color */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  font-weight: bold;
}

.options-btn {
  cursor: pointer;
  font-size: 20px; /* Adjust the font size */
  padding: 10px 5px; /* Adjust the padding */
}

.hoverable-cell {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hoverable-cell:hover {
  background-color: #e0e0e0; /* Change to desired hover background color */
  text-decoration: underline;
  color: blue; /* Change to desired hover text color */
}

.chat-fixed-top {
  position: fixed;
  padding-left: 20px;
  padding-top: 10px;
  top: 65px;
  margin-left: -20px;
  width: calc(100vw - 450px);
  height: 55px;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.chat-fixed-form {
  position: fixed;
  display: flex;
  padding-left: 20px;
  padding-top: 10px;
  bottom: 20px;
  margin-left: -20px;
  width: calc(100vw - 450px);
}

.chat-mytextinput {
  padding: 10px;
  margin-left: 10px;
  width: calc(100vw - 600px);
  max-height: 100px;
  margin-right: 10px;
}

#club-chat-scrollable-div {
  margin-top: 8px;
  height: calc(100vh - 200px);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

#chat-scrollable-div {
  margin-top: 55px;
  height: calc(100vh - 200px);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

#group_discussion_item {
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin-bottom: 10px;
}

#unselected-chat-left-menu,
#selected-chat-left-menu {
  position: fixed;
  top: 60px;
  left: 100px;
  width: 300px;
  background: #f2f2f2;
  height: calc(100vh - 60px);
  overflow-y: auto;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

#chat-page {
  margin-left: 300px;
}

#new-chat-select {
  margin-top: 220px;
  margin-left: calc(280px + 8vw);
  padding: 60px;
}

#chat-send {
  margin-left: 30vw;
  max-width: 50vw;
}

#chat-receive {
  margin-left: 0;
  max-width: 50vw;
}

.ui.cards {
  margin-left: 0%;
}

.featuredFeed {
  position: fixed;
  width: 420px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  top: auto;
  bottom: auto;
  background-color: white 80%;
  scrollbar-width: none;
}

/* width */
.featuredFeed::-webkit-scrollbar {
  width: 1px;
}

/* .ui.form {
  padding: 0.5rem;
} */

/* .ui.segment.postImage {
  margin-left: 3rem;
  margin-right: 3rem;
} */

#scrollableDiv::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.twenty-chars {
  width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #444;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #444;
}

#table-contents {
  overflow-x: auto;
  margin-right: 100px;
  margin-top: 16px;
  min-height: calc(100vh - 250px);
}
#table-contents::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.three-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #444;
}

@media only screen and (max-width: 800px) {
  .featured-feed {
    display: none;
  }
  #club-nav-menu {
    display: none;
  }
  #channel-nav-menu {
    margin-left: 120px;
  }
  #selected-chat-left-menu {
    display: none;
  }
  #chat-page {
    margin-left: 0;
  }
  .club-detailed-page {
    margin-left: 0;
  }
  #manage-club-menu {
    left: 100px;
  }
  .chat-fixed-top {
    width: 100vw;
  }
  .chat-mytextinput {
    width: calc(100vw - 300px);
  }
}

@media only screen and (max-width: 1000px) {
  .right-section {
    display: none;
  }
  .left-section {
    max-width: 100vw;
  }
}
