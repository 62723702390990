@tailwind base;
@tailwind components;
@tailwind utilities;

.benefits {
  color: black;
  margin-top: 32px;
}

.slider-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  display: flex;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.slider-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
}

.slider-item {
  flex-shrink: 0;
  width: 100%; /* Each item takes full width */
  text-align: center;/* Snap to the center of the screen */
}

.dot-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.dot-container span {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: gray;
}

.dot-container span.bg-blue-700 {
  background-color: #1d4ed8; /* Active indicator color */
}

button {
  background-color: #0073b1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
